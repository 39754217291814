import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/NoteLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`TypeScript is a gradually typed programming language. It’s based on
JavaScript, and destined for easy migration, so you can statically type the
parts of your code you care about, and leave other, less critical parts,
dynamically typed.`}</p>
    <p>{`That’s the obvious side of gradual typing. I’m more interested in the other
side. With the compiler option `}<inlineCode parentName="p">{`strict: true`}</inlineCode>{`, we can write TypeScript which
is entirely statically typed, but the gains from gradual typing don’t end
here.`}</p>
    <p>{`What’s unapparent for many who connect `}<em parentName="p">{`static`}</em>{` typing with `}<em parentName="p">{`strong`}</em>{` typing,
TypeScript often lets you type your code `}<strong parentName="p">{`stronger`}</strong>{` than Java, C# or C++,
languages that were statically typed from the beginning. By stronger, I mean
`}<em parentName="p">{`closer to your intent or business domain`}</em>{`.`}</p>
    <p>{`Why is it so? Well, that’s quite funny if you think about it.`}</p>
    <p><strong parentName="p">{`TypeScript needs to deal with JavaScript’s bullshit.`}</strong></p>
    <p>{`You know JavaScript. It’s expressive, it works everywhere, tons of people of
different backgrounds are using it. It’s also quirky. It has some bad parts.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1380px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "56.231884057971016%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsSAAALEgHS3X78AAAClElEQVQoz2Ooz0mozoipSI2sTo/sKkksSQ3V1dZydXTYu2pxmLcHOxu3rJQcFwefuJCIhb5mUrBnU0FKQ35afV5SY14iQ3lKaEtBfHdZcldZYklScH6sb01hRlN12YpZEzYumTu7t7soKSol1LOvrnBuf9uiqROWzpy+btGCtXOnt5dkMrQXJ9ZkRmREeAe72lroacb5eTTmZeREhpYlxK2fM/PC3s1TGwsXTuyZ1jtj68r1x3bu3b5qw/Zlay7u2TWhtpghwtPew9rUSl/bSk8jOtArISXe1d/LJtAnqCS/bebUhb0NSye0bJ43p6GwNj02pyKztKG8sbWlZ9O69dNbqhnMdNSBFqaGuHZXZZT3tia2NxRN7CyZ2F03a8q0WROm1uf19ndGt7a7VLa4lTVZpxUa5JQppOXrZuUvmdDJICksHOBksW5647Ujux9cOPXs0umXl09/uH7+8/ULyyc0zmwt862s4k/JEs8skM0oUMzM18spMM7O86+uWT9vBoO8uFhTXuyaGX1bF8/eOG/Kymm9C/paJzaUd5RlT6zOWj2jb9f2zQf37Dh5YNfZA7suHNx9/uCeiwf2XDm49+TWjQzFCUFdpYkxXjbBjkYBdvreVtoe5hpOhsr2egrV6RE7l869tGfr9f07zu3YeH7n5sv7dl47uPvcrq0ntmw8uWUjQ7inXUdxQkmiX4izcZCjkb+dgZ+dfoCDYYC9Qay3TVlKeFqYj5u1uY6amo6aur6mpoGmlraqmpqCoryUDENSkFtbQVxLfkx9VnhdZmhDdlhFSkB2hFuIq4Wlgbq8pCgrMzMDLgBMHm2F8a0FcXVZERnhHm5WhhqKMtycXAwMjDAljIyMzExMLCASzICwGRmZAK41CQMLCKfNAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "javascript bad parts",
          "title": "javascript bad parts",
          "src": "/static/64536cde2ccf88e32b403b0be147912f/b1001/javascript-bad-parts.png",
          "srcSet": ["/static/64536cde2ccf88e32b403b0be147912f/e4d6b/javascript-bad-parts.png 345w", "/static/64536cde2ccf88e32b403b0be147912f/1e043/javascript-bad-parts.png 690w", "/static/64536cde2ccf88e32b403b0be147912f/b1001/javascript-bad-parts.png 1380w", "/static/64536cde2ccf88e32b403b0be147912f/a6d66/javascript-bad-parts.png 2070w", "/static/64536cde2ccf88e32b403b0be147912f/35d5c/javascript-bad-parts.png 2760w", "/static/64536cde2ccf88e32b403b0be147912f/2a4bc/javascript-bad-parts.png 3264w"],
          "sizes": "(max-width: 1380px) 100vw, 1380px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`To create a language allowing to statically type `}<em parentName="p">{`idiomatic JavaScript`}</em>{` is a
challenge, because popular JavaScript idioms are seldom possible to express
conveniently in a conventional statically typed language.`}</p>
    <p>{`Think of accessing a deeply nested value by a dotted path.`}</p>
    <pre {...{
      "className": "night-owl-no-italics vscode-highlight",
      "data-language": "tsx"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`const `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`x`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` = { a: { b: { c: `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`banana`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` } } }`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk7"
          }}>{`get`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(x, `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`a.b.c`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`) `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`===`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`banana`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span></code></pre>
    <p>{`Typing `}<inlineCode parentName="p">{`get`}</inlineCode>{` as `}<inlineCode parentName="p">{`(object, string) -> unknown`}</inlineCode>{` is clearly inconvenient. I
want to know that `}<inlineCode parentName="p">{`get(x, 'a.d')`}</inlineCode>{` is incorrect without leaving my editor,
and I want to know that `}<inlineCode parentName="p">{`get(x, 'a.b')`}</inlineCode>{` is `}<inlineCode parentName="p">{`{ c: string }`}</inlineCode>{` or even
`}<inlineCode parentName="p">{`{ c: 'banana' }`}</inlineCode>{`.`}</p>
    <p>{`Why would you need such a weird API if you can just write `}<inlineCode parentName="p">{`x.a.b.c`}</inlineCode>{` (or
`}<inlineCode parentName="p">{`x?.a?.b?.c ?? 'default'`}</inlineCode>{` to handle missing value)? It’s quite convenient
for embedded styling DSLs and similar declarative constructs.`}</p>
    <pre {...{
      "className": "night-owl-no-italics vscode-highlight",
      "data-language": "tsx"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk9"
          }}>{`<p `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`css`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`=`}</span><span parentName="span" {...{
            "className": "mtk24"
          }}>{`{`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`{ color: `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`primary.500`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`, bgColor: `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`gray.200`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{` }`}</span><span parentName="span" {...{
            "className": "mtk24"
          }}>{`}`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`>`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`Hello world!`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`</p>`}</span></span></code></pre>
    <p><a parentName="p" {...{
        "href": "https://github.com/microsoft/TypeScript/pull/40336"
      }}>{`Template literal types`}</a>{`
come to the rescue here.`}</p>
    <p>{`This is of course, not the only interesting case. Have a highly dynamic
function which returned typed differs greatly based on input? Generics are
not sufficient?
`}<a parentName="p" {...{
        "href": "https://www.typescriptlang.org/docs/handbook/2/conditional-types.html"
      }}>{`Conditional types`}</a>{`
have got your back.`}</p>
    <p>{`While its simple type inference, the lack of pattern matching and the lack
of higher kinded types (you can fake them with a hack,
`}<a parentName="p" {...{
        "href": "https://gist.github.com/gcanti/2b455c5008c2e1674ab3e8d5790cdad5"
      }}>{`see `}<inlineCode parentName="a">{`fp-ts`}</inlineCode></a>{`)
may discourage typed functional programming enthusiasts, TypeScript has
nothing to be ashamed of when it comes to domain modeling capabilities.`}</p>
    <p>{`Sum types, product types, mapping over types. TypeScript has got it all, and
it adds a bunch of unique features needed to statically type idiomatic
JavaScript.`}</p>
    <p>{`This is a language that can spoil you. It certainly spoiled me. Many
languages I use feel… “less powerful” now.`}</p>
    <p>{`The important question is: do you need powerful language features to build
good, reliable software?`}</p>
    <p>{`I wouldn’t say so.`}</p>
    <hr></hr>
    <p>{`There are many `}<a parentName="p" {...{
        "href": "/notes/typescript-exercises-and-challenges",
        "title": "TypeScript Exercises and Challenges"
      }}>{`TypeScript Exercises and Challenges`}</a>{` to learn these unique
and strange features.`}</p>

    <style {...{
      "className": "vscode-highlight-styles"
    }}>{`
  
  .night-owl-no-italics {
background-color: #011627;
color: #d6deeb;
}

.night-owl-no-italics .mtk1 { color: #D6DEEB; }
.night-owl-no-italics .mtk2 { color: #011627; }
.night-owl-no-italics .mtk3 { color: #C792EA; }
.night-owl-no-italics .mtk4 { color: #82B1FF; }
.night-owl-no-italics .mtk5 { color: #ADDB67; }
.night-owl-no-italics .mtk6 { color: #637777; }
.night-owl-no-italics .mtk7 { color: #82AAFF; }
.night-owl-no-italics .mtk8 { color: #F78C6C; }
.night-owl-no-italics .mtk9 { color: #7FDBCA; }
.night-owl-no-italics .mtk10 { color: #FF5874; }
.night-owl-no-italics .mtk11 { color: #FFEB95; }
.night-owl-no-italics .mtk12 { color: #FFCB8B; }
.night-owl-no-italics .mtk13 { color: #7FDBCAFF; }
.night-owl-no-italics .mtk14 { color: #80CBC4; }
.night-owl-no-italics .mtk15 { color: #FF6363; }
.night-owl-no-italics .mtk16 { color: #57EAF1; }
.night-owl-no-italics .mtk17 { color: #5F7E97; }
.night-owl-no-italics .mtk18 { color: #ECC48D; }
.night-owl-no-italics .mtk19 { color: #B2CCD6; }
.night-owl-no-italics .mtk20 { color: #FAD430; }
.night-owl-no-italics .mtk21 { color: #FFFFFF; }
.night-owl-no-italics .mtk22 { color: #FF2C83; }
.night-owl-no-italics .mtk23 { color: #020E14; }
.night-owl-no-italics .mtk24 { color: #D3423E; }
.night-owl-no-italics .mtk25 { color: #EC5F67; }
.night-owl-no-italics .mtk26 { color: #8BD649; }
.night-owl-no-italics .mtk27 { color: #5CA7E4; }
.night-owl-no-italics .mtk28 { color: #A2BFFC; }
.night-owl-no-italics .mtk29 { color: #EF535090; }
.night-owl-no-italics .mtk30 { color: #ADDB67FF; }
.night-owl-no-italics .mtk31 { color: #697098; }
.night-owl-no-italics .mtk32 { color: #FF869A; }
.night-owl-no-italics .mtk33 { color: #DDDDDD; }
.night-owl-no-italics .mtk34 { color: #E0DEC6; }
.night-owl-no-italics .mtk35 { color: #CDEBF7; }
.night-owl-no-italics .mtk36 { color: #D9F5DD; }
.night-owl-no-italics .mtk37 { color: #6AE9F0; }
.night-owl-no-italics .mtk38 { color: #C789D6; }
.night-owl-no-italics .mtk39 { color: #BEC5D4; }
.night-owl-no-italics .mtk40 { color: #31E1EB; }
.night-owl-no-italics .mtk41 { color: #8EACE3; }
.night-owl-no-italics .mtk42 { color: #78CCF0; }
.night-owl-no-italics .mtk43 { color: #FAF39F; }
.night-owl-no-italics .mtk44 { color: #D7DBE0; }
.night-owl-no-italics .mtk45 { color: #7986E7; }
.night-owl-no-italics .mtki { font-style: italic; }
.night-owl-no-italics .mtkb { font-weight: bold; }
.night-owl-no-italics .mtku { text-decoration: underline; text-underline-position: under; }
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      